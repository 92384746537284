// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Container, 
  Typography, 
  Box, 
  Button, 
  Grid, 
  Link, 
  FormControlLabel, 
  FormControl, 
  FormLabel, 
  RadioGroup, 
  Switch, 
  Collapse, 
  CssBaseline, 
  Divider, 
  Toolbar, 
  AppBar, 
  Modal, 
  CircularProgress
  } from '@mui/material';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DrawerMenu from '../Shared/DrawerMenu';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { style, config, StyledRadio } from '../../../config/settings.jsx';
import { getTheme} from '../../../config/theme.jsx';
import { api } from '../../../config/apis.jsx'
import DonatedBox from '../../modules/TotalDebitsModule';


const validationSchema = yup.object({
    amount: yup.number().required('Amount is required').max(250, 'Amount must be $250 or less'),
     });

const Dashboard = () => {
    const [assetsPath, setAssetsPath] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDonation, setIsLoadingDonation] = useState(false);
    const [message, setMessage] = useState('');
    const [roundUpMessage, setRoundUpMessage] = useState('');
    const navigate = useNavigate();
    const [cdrid, setCdrid] = useState('');
    const [clubId, setClubId] = useState('');
    const userId = localStorage.getItem('userId');
    const [roundUpsOn, setRoundUpsOn] = useState(true);
    const [linkedSavingsOn, setLinkedSavingsOn] = useState();
    const [weeklyLimitOn, setWeeklyLimitOn] = useState(false);
    const [weeklyLimitValue, setWeeklyLimitValue] = useState(0);
    const [newWeeklyLimitValue, setNewWeeklyLimitValue] = useState();
    const [roundUpValue, setRoundUpValue] = useState(100);
    const [newRoundUpValue, setNewRoundUpValue] = useState();
    const [supportedClub, setSupportedClub] = useState('');
    const [savingsAccountEnabled, setSavingsAccountEnabled] = useState(false);
    const [savingsAccountValue, setSavingsAccountValue] = useState('');
    const [SavingsAccountName, setSavingsAccountName] = useState('');
    const [roundUpLabel, setRoundUpLabel] = useState('');
    const [weeklyLimitLabel, setWeeklyLimitLabel] = useState('');
    const [linkedSavingsLabel, setLinkedSavingsLabel] = useState('');
    const [linkedSavingsExists, setLinkedSavingsExists] = useState(false);
    const [savingsAccountMissing, setSavingsAccountMissing] = useState('');
    const [totalDebitAmount, setTotalDebitAmount] = useState(0);
    const [sortedDebitHistory, setSortedDebitHistory] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openNotConnected, setOpenNotConnected] = React.useState(false);
    const handleOpenNotConnected = () => setOpenNotConnected(true);
    const handleCloseNotConnected = () => setOpenNotConnected(false);
    const [isLoadingReconsent, setIsLoadingReconsent] = useState(false);
    const [openNotFinished, setOpenNotFinished] = React.useState(false);
    const handleOpenNotFinished = () => setOpenNotFinished(true);
    const handleCloseNotFinished = () => setOpenNotFinished(false);
    const [isLoadingNotFinished, setIsLoadingNotFinished] = useState(false);
    const [openPauseThem, setOpenPauseThem] = React.useState(false);
    const handleOpenPauseThem = () => setOpenPauseThem(true);
    const handleClosePauseThem = () => setOpenPauseThem(false);
    const [isPausedLoading, setIsPausedLoading] = useState(false);
    const [isDonated, setIsDonated] = useState(false);
    const isDonatedMessage = 'Your donation request has been received and may take 2-3 days to process';
    const [externalUserId, setExternalUserId] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);



    const handlePauseToggling = async () => {
      if (roundUpsOn) {
        setOpenPauseThem(true)
      }
    else {
      setRoundUpsOn((prev) => !prev); 
      try {
         const response = await axios.post(
           api.setPaused,
           { cdrid: cdrid, isUserPausedRoundUp: roundUpsOn }, // Send the correct value
           { headers: { 'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
             
            } }
         );
       } catch (error) {
         setRoundUpMessage('Something went wrong, please try again');
       }
     };
    };
    
    
    const formik = useFormik({
      initialValues: {
        amount: 0,
        cdrid: localStorage.getItem('cdrid'),
      },
      validationSchema: validationSchema,
         onSubmit: async (values) => {
         setIsLoadingDonation(true);
        try {
      
          const response = await axios.post(api.donateNow, {values},
            { headers: { 'Content-Type': 'application/json',
              apikey: config.apikey,
              tenant: config.tenant
             } }
          );
        setIsDonated(true);

        setTimeout(() => {
        setOpen(false);
        setIsDonated(false);    
      }, 4000); // 3000ms = 3 seconds

           

          } catch (error) {
          console.error('Error processing donation:', error);
          setMessage('Failed to process donation. Please try again.');
        }
      },
    });




    const handleRoundUpsToggle = async () => { 
      setIsPausedLoading(true);
          setRoundUpsOn((prev) => !prev); 
     try {
        const response = await axios.post(
          api.setPaused,
          { cdrid: cdrid, isUserPausedRoundUp: roundUpsOn }, // Send the correct value
          { headers: { 'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
           } }
        );
        setOpenPauseThem(false);
        setIsPausedLoading(false);
      } catch (error) {
        setRoundUpMessage('Something went wrong, please try again');
      }
    };

  const handleLinkedSavingsToggle = async () =>  {
    setLinkedSavingsOn((prev) => !prev);
    let savingsAccountEnabledValue;
    if (linkedSavingsOn) {
      savingsAccountEnabledValue = false;
    } else {
      savingsAccountEnabledValue = true;
      };
      try {
        const response = await axios.post(
          api.setSavingsAccount,
          { cdrid: cdrid, savingsAccountEnabled: savingsAccountEnabledValue }, // Send the correct value
          { headers: { 'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
           } }
        );
      } catch (error) {

      }
    };
    
  
 
    const handleWeeklyLimitToggle = async () => { 
      setWeeklyLimitOn((prev) => !prev);
      let weeklyLimitValueUpdate;
      if (weeklyLimitOn) {
        weeklyLimitValueUpdate = 0
      } else {
        if (weeklyLimitValue === 0) {
          setWeeklyLimitValue(1000);
          weeklyLimitValueUpdate = 1000;
        } else
          weeklyLimitValueUpdate = weeklyLimitValue
      };
    try {
         const response = await axios.post(
          api.setWeeklyLimit,
        { cdrid: cdrid, weeklyMaximumLimit: weeklyLimitValueUpdate }, // Send the correct value
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
    } catch (error) {
   //   setRoundUpMessage('Something went wrong, please try again');
    }
  };


  const handleRoundUpValueChange = async (event) => {
    const newRoundUpValue = parseInt(event.target.value, 10);
    setRoundUpValue(newRoundUpValue);
    try {
      const response = await axios.post(
        api.setRoundUpAmount,
        { roundupAmountCents: newRoundUpValue, cdrid: cdrid },
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
    } catch (error) {

    }
  };

  const handleWeeklyLimitChange = async (event) => {
    const newWeeklyLimitValue = parseInt(event.target.value, 10);
    setWeeklyLimitValue(newWeeklyLimitValue);
    try {
      const response = await axios.post(
        api.setWeeklyLimit,
        { weeklyMaximumLimit: newWeeklyLimitValue, cdrid: cdrid },
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
    } catch (error) {

    }
  };
  
  const goToLinkedAccounts = () => navigate('/linked-accounts');

  

  const sortDebitsByDate = (debits) => {

 const monthMap = {
          Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
      };


   
  return debits.sort((a, b) => {
    const [aMonth, aYear] = a.monthYear.split('-');
    const [bMonth, bYear] = b.monthYear.split('-');

    // Convert month abbreviations to numeric values using the monthMap
    const aMonthNumeric = monthMap[aMonth];
    const bMonthNumeric = monthMap[bMonth];

    // Create date strings in the format YYYY-MM for accurate sorting
    const aDateStr = `20${aYear}-${aMonthNumeric}-01`;
    const bDateStr = `20${bYear}-${bMonthNumeric}-01`;

    // Convert to Date objects
    const aDate = new Date(aDateStr);
    const bDate = new Date(bDateStr);

    // Sort in descending order
    return bDate - aDate;
  });
};


  const finishSetup = () => {
    navigate('/launch-consent');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadConfigAndAssets();
        await getUserRecord();
        setDataLoaded(true); // Indicate that initial data has been loaded
      } catch (error) {
        console.error('Error fetching data:', error);
        setMessage('Failed to load data. Please try again later.');
      } finally {
   
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchAdditionalData = async () => {
      if (!dataLoaded) return;

      try {
        await Promise.all([

          getTotalDebits(),
          getClubName(),
          getSavingsAccount(),
        ]);
      } catch (error) {
        console.error('Error fetching additional data:', error);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchAdditionalData();
  }, [dataLoaded]);

  useEffect(() => {
    const fetchConsentStatus = async () => {
      if (externalUserId) {
        await getUserConsentStatus(externalUserId);
      }
    };

    fetchConsentStatus();
  }, [externalUserId]);



    const loadConfigAndAssets = async () => {

      let subdomain;
  
      const hostname = window.location.hostname;
      const parts = hostname.split('.');
      if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
        subdomain = parts[0];
        setSubdomain(subdomain);
      } else {
        subdomain = 'default';
      }
      localStorage.setItem('host', subdomain);
      console.log('subdomain:', subdomain)
      try {
        const settings = await import(`../../../config/${subdomain}/settings.jsx`);
        setAssetsPath(settings.logoURLDashboard);
      } catch (error) {
        console.error('Error loading theme and assets:', error);
      }
    };




  
  
    const getUserRecord = async () => {
      if (!userId) {
        navigate('/');
        return;
      }
  
      try {
        const response = await axios.post(api.getUser, { userId }, {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant,
          },
        });
        const userRecord = response.data.userRecord.document;
        const cdridA = userRecord._id;
        const externalUserIdA = userRecord.externalUserId;
        localStorage.setItem('cdrid', cdridA);
        setExternalUserId(externalUserIdA);
        setCdrid(cdridA);
        setClubId(userRecord.supportingClubId);
  
        if (!userRecord.isConsent) {
          setOpenNotConnected(true);
        } else if (!userRecord.onboardingComplete) {
          setOpenNotFinished(true);
        }
  
        if (userRecord.isUserPausedRoundUp || userRecord.roundupAmountCents === '0') {
          setRoundUpsOn(false);
          setRoundUpLabel('Paused');
        } else {
          setRoundUpsOn(true);
          setRoundUpValue(userRecord.roundupAmountCents);
          setRoundUpLabel('On');
        }
  
        if (userRecord.weeklyMaximumLimit && userRecord.weeklyMaximumLimit > '0') {
          setWeeklyLimitOn(true);
          setWeeklyLimitValue(userRecord.weeklyMaximumLimit);
          setWeeklyLimitLabel('On');
        } else {
          setWeeklyLimitOn(false);
          setWeeklyLimitLabel('Off');
        }
  
        if (userRecord.savingsAccountEnabled) {
          setLinkedSavingsOn(true);
          setLinkedSavingsLabel('On');
        } else {
          setLinkedSavingsOn(false);
          setLinkedSavingsLabel('Off');
        }
  

      } catch (error) {
        setMessage('Failed to register. Please try again.');
      }
    };


    useEffect(() => {
      const fetchConsentStatus = async () => {
        if (externalUserId) { // Only fetch if externalUserId is available
          await getUserConsentStatus(externalUserId);
        }
      };
    
      fetchConsentStatus();
    }, [externalUserId]); // Depend on externalUserId, so it runs when it changes


    const getTotalDebits = async () => {
      if (!cdrid) return;
  
      try {
        const response = await axios.post(
          api.retrieveTotalDebits,
          { cdrid },
          {
            headers: {
              'Content-Type': 'application/json',
              apikey: config.apikey,
              tenant: config.tenant,
            },
          }
        );
  
        const sortedDebits = sortDebitsByDate(response.data.debitHistory.documents);
        setSortedDebitHistory(sortedDebits);
      } catch (error) {
        console.error('Failed to retrieve total payments', error);
      }
    };




    const getUserConsentStatus = async (externalUserId) => {
      try {
        const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/check-consent', { externalUserId }, {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant,
          }
          
        });
        if (response.data.activeConsents === false) {
            setOpenNotConnected(true);   
        }
        console.log(response);
      } catch (error) {
      }};


    const getClubName = async () => {
      if (!clubId) return;
  
      try {
        const response = await axios.get(api.retrieveClubs, {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant,
          },
        });
  
        const clubs = response.data.clubs;
        const club = clubs.find((club) => club._id === clubId);
        if (club) {
          setSupportedClub(club.name);
        }
      } catch (error) {
        setMessage('Failed to find Clubs. Please try again.');
      }
    };
  
    const getSavingsAccount = async () => {
      if (!cdrid) return;
  
      try {
        const response = await axios.post(api.retrieveLinkedSavings, { userId: cdrid }, {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant,
          },
        });
  
        if (response && response.data.savingsAccount) {
          setLinkedSavingsExists(true);
          const accountName = response.data.savingsAccount.document.accountName;
          const account = response.data.savingsAccount.document.accountNumber;
          const lastFourDigits = account.slice(-4);
          const maskedSection = account.slice(0, -4).replace(/\d/g, 'X');
          const displayAccount = maskedSection + lastFourDigits;
          setSavingsAccountValue(displayAccount);
          setSavingsAccountName(accountName);
        } else {
          setSavingsAccountMissing('No account linked');
        }
      } catch (error) {
        console.error('Failed to retrieve linked savings', error);
        setMessage('Failed to retrieve linked savings.');
      } 
    };
  

return (
    <ThemeProvider theme={getTheme}>
       {isLoadingData ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 
      
       
  
        <Grid item xs={12} justifyContent="center" height={3}>
      
    </Grid>
    <DonatedBox assetsPath={assetsPath} cdrid={cdrid} clubId={clubId} />

    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Round-Ups {roundUpLabel}
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch  checked={roundUpsOn} onChange={handlePauseToggling} />}
                />
              </Grid>
            </Grid>     
            <Collapse in={roundUpsOn}>  
      
     <Box display="flex" alignItems="center" width="100%" mt="10px">  
     <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Round-Up to the nearest...</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={roundUpValue}
        onChange={handleRoundUpValueChange}
    >
        <FormControlLabel value={100} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$1" />
        <FormControlLabel value={200} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$2" />
        <FormControlLabel value={500} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$5" />
        
      
      </RadioGroup>
    </FormControl>
     </Box>    
            </Collapse>
            
            
         <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Weekly Limit {weeklyLimitLabel}
                                  </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch checked={weeklyLimitOn} onChange={handleWeeklyLimitToggle}/>}
                />
              </Grid>
            </Grid>
            <Collapse in={weeklyLimitOn}>      
            <Box display="flex" alignItems="center" width="100%" mt="10px">  
     <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Limit round-up amount per week:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={weeklyLimitValue}
        onChange={handleWeeklyLimitChange}
    >
        <FormControlLabel value={750} control={<StyledRadio  icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$7.50" />
        <FormControlLabel value={1000} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$10.00" />
        <FormControlLabel value={1250} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="primary"/>}  />}  label="$12.50" />
        
      
      </RadioGroup>
    </FormControl>
     </Box>    
            </Collapse>
        
    </Box>

    {subdomain !== 'aca' && (

    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2}  borderRadius={4} >
    <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Linked Savings Account {linkedSavingsLabel}
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch checked={linkedSavingsOn} onChange={handleLinkedSavingsToggle} />}
                />
              </Grid>
            </Grid>
            <Collapse in={linkedSavingsOn}>   

            <Box display="flex" alignItems="center" width="100%" mt='10px'>
                                  {SavingsAccountName}
                  </Box>

            <Box display="flex" alignItems="center" width="100%" mt='10px'>
                                  {savingsAccountValue}
                  </Box>
                  </Collapse>
            <Box display="flex" alignItems="center" width="100%" mt='10px'>                  
            <Button
            onClick={goToLinkedAccounts}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '150%' }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Manage Linked Account'}
         
          </Button>
          </Box>
            

        

     

    </Box>  )}   

    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Grid container alignItems="center">
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt='10px'>  
                <Typography variant="body2" mt={1}  sx={{ fontWeight: 'bold' }}>
                  One-Time Donation
                </Typography>  
                </Box>            
            </Grid>
   
            <Box display="flex" alignItems="center" width="100%" mt='10px'>

            <Button
            onClick={handleOpen}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, }}
           
          >
           Donate Now
         
          </Button>
          </Box>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isDonated ? (
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           One-Off Donation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          You can donate up to $250 in a one-off Donation
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClose}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingDonation}
          >
            <Typography >
            {isLoadingDonation ? 'Loading...' : 'Submit'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

          </form>

        </Box> ) : (
      <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
        Thank You!
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
        {isDonatedMessage}
      </Typography>
    </Box>
        )}
        </Modal>

         
    </Box>


    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
      <Grid container alignItems="center">
        <Box display="flex" alignItems="center" width="100%" mt='10px'>  
          <Grid item xs={8}>
            <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
              Donation History
            </Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Typography variant="body2" mt={1}>
              <Link href="/history" underline="always" color="inherit">View All</Link>
            </Typography>
          </Grid>
        </Box>            
      </Grid>

      {sortedDebitHistory.map((debit, index) => (
        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" width="100%" mt='10px'>
          <Typography variant="body2">
            {debit.monthYear}:
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'right', flexGrow: 1 }}>
            ${((debit.totalDebitAmount / 100).toFixed(2))}
          </Typography>
        </Box>
      ))}
    </Box>
    <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>
    <Modal
        open={openNotConnected}
        onClose={handleCloseNotConnected}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           No Bank Connection
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          We noticed you don't have an active bank account connected for round-ups.
          </Typography>
      
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleCloseNotConnected}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button onClick={finishSetup}
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingReconsent}
          >
            <Typography >
            {isLoadingReconsent ? 'Loading...' : 'Connect'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

    

        </Box>
        </Modal>

        <Modal
        open={openNotFinished}
        onClose={handleCloseNotFinished}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           Finish Setting Up Your Account
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          We noticed you haven't finished selecting your Round-Up and Funding Accounts.
          </Typography>
      
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleCloseNotFinished}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button onClick={finishSetup}
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingNotFinished}
          >
            <Typography >
            {isLoadingNotFinished ? 'Loading...' : 'Finish'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

    

        </Box>
        </Modal>

        <Modal
        open={openPauseThem}
        onClose={handleClosePauseThem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           Pause Round-Ups
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
        Are you sure you want to pause your round-ups? There may still be pending direct-debits which will complete, event if you pause.
          </Typography>
      
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClosePauseThem}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button onClick={handleRoundUpsToggle}
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isPausedLoading}
          >
            <Typography >
            {isPausedLoading ? 'Loading...' : 'Confirm'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

    

        </Box>
        </Modal>
    </Container>
            </CssBaseline></>)}
            </ThemeProvider>
       
  );

}
export default Dashboard;

export {};