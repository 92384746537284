import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { style, config, StyledRadio } from '../../../config/settings.jsx';
import { getTheme } from '../../../config/theme';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';
  



  const Consented = () => {
    const [subdomain, setSubdomain] = useState('');
    const [isLoadingTheme, setIsLoadingTheme] = useState(false);
    const [assetsPath, setAssetsPath] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRetry, setIsLoadingRetry] = useState(false);
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
    const [pageTitle, setPageTitle] = useState('Connecting...');
    const location = useLocation();
    const cdrid = localStorage.getItem('cdrid');
    const [stepperStep, setStepperStep] = useState(1);
    const [reconsentRequired, setReconsentRequired] = useState(false);
    const [logOut, setLogOut] = useState(false);
    const [newConsentLink, setNewConsentLink] = useState('');
    const [verify, setVerify] = useState(false);
    
{/* Host name identification and configassets loading */}
useEffect(() => {
  setIsLoadingTheme(true);
  let subdomain;
  
  const loadConfigAndAssets = async () => {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.'); 
  if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
    subdomain = parts[0];
    setSubdomain(subdomain); 
  } else {
    subdomain = 'default';
  }
  localStorage.setItem('host', subdomain);

  const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setAssetsPath(`/assets/images/${subdomain}/`);  // Dynamic asset folder
  
  console.log(settings)
  setIsLoadingTheme(false);
  };

  loadConfigAndAssets();

}, []);

  
    const fetchJob = async (jobId, retries = 0) => {

      const maxRetries = 5;
      try {
        const response = await axios.post(
         api.checkJob,
          { jobId: jobId, cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json',    apikey: config.apikey,
            tenant: config.tenant } }
        );
  
        const status = response.status;
        if (status === 200) {
          setStepperStep(2);  
          setPageTitle('Finding Accounts!');
          setStatusMessage('Successfully connected - we are now retrieving your accounts.');
          const retrieveAccounts = await axios.post(
            api.retrieveAccounts,
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json',    apikey: config.apikey,
            tenant: config.tenant } }
          );
          if (retrieveAccounts.status === 200) {
            setPageTitle('Success!');
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
        } else if (status === 202) {
          setPageTitle('Checking!');
          setStatusMessage('Receiving communications from your bank. This might take a little while.');
          if (retries < maxRetries) {
          setTimeout(() => fetchJob(jobId, retries + 1), 5000);
        } else {
          const findAccounts = await axios.post(
            api.retrieveAccounts,
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json',    apikey: config.apikey,
            tenant: config.tenant } }
          );
          if (findAccounts.status === 200) {
            setPageTitle('Success!');
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
          setPageTitle('Verification');
          setVerify(true);
          setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
          setLogOut(true);
        } }
      } catch (error) {
        setPageTitle('Verification');
        setVerify(true);
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
        setLogOut(true);
      }
    };
  
    const findJobIds = async (cdrid) => {
      try {
        const response = await axios.post(
          api.findJobs,
          { cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json',    
            apikey: config.apikey,
            tenant: config.tenant } },
        );
        const jobId = response.data.jobId;
        const status = response.status;
  
        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Confirming Connection');
          setStatusMessage('FOUND! Finalising connection with your bank');
          await fetchJob(jobId);
        }
      } catch (error) {
        setPageTitle('Verification');
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
        setVerify(true);
        setLogOut(true);
      }
    };

      
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');
      localStorage.setItem('jobId', jobId);
      const clubId = localStorage.getItem('clubId');

      if( clubId === '6503a0a55e3b99bdc8236198') {
        window.location.href = 'https://lifechanger.sipora.io/consented';
      }
  
      if (!jobId) {
        setPageTitle('Searching For Connection!');
        setStatusMessage('We are having some trouble confirming your connection, this might take a minute');
        findJobIds(cdrid);
        return;
      }
  
      fetchJob(jobId);
    }, [location.search, cdrid]);



  const handleRetry = async () => {
    setIsLoadingRetry(true);
    try {
      const response = await axios.post(api.consent,
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
        apikey: config.apikey,
            tenant: config.tenant
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoadingRetry(false)
    }
  };

  const logOutButton = async () => {
    navigate('/logout');
  };

  const verifyButton = async () => {
    navigate('/verify');
  };


  return (
    <ThemeProvider theme={getTheme}>
    <Container component="main" maxWidth="xs">
    {isLoadingTheme ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={16} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       {statusMessage}
      </Typography>
      {reconsentRequired && (
                <Button
                onClick={handleRetry}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                disabled={isLoadingRetry}
    
                      
              >
                {isLoadingRetry ? 'Loading...' : 'Retry'}
             
              </Button>
              )}
              {verify && (
                <Button
                onClick={verifyButton}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
              
    
                      
              >
              Verify My Account
             
              </Button>
              )}
   
      </Box>

      
    
      </Grid>
      



                    
        {footerLinks}
        </Box></>)}
    </Container></ThemeProvider>
  );
};

export default Consented;

export {};