const URL = `https://minerva.sipora.io/api`

export const api = {
  register: `${URL}/client/round-up/user/register`,
  consent: `${URL}/client/round-up/user/consent`,
  accounts: `${URL}/client/round-up/user/accounts`,
  manageConsent: `${URL}/client/round-up/user/manage-consent`,
  getUser: `${URL}/client/round-up/user/get-user`,
  retrieveTotalDebits: `${URL}/client/round-up/user/retrieve-total-debits`,
  debitsYTD: `${URL}/client/round-up/user/debits-ytd`,
  debitsAllTime: `${URL}/client/round-up/user/debits-total`,

  retrieveHistory: `${URL}/client/round-up/user/retrieve-history`,
  setRoundUpAmount: `${URL}/client/round-up/user/set-roundup-amount`,
  setWeeklyLimit:  `${URL}/client/round-up/user/set-weekly-limit`,
  retrieveClubs: `${URL}/clubs/retrieve`,
  retrieveLinkedSavings: `${URL}/client/round-up/user/linked-savings`,
  submitSavingsAccount: `${URL}/client/round-up/user/submit-savings-account`,
  setSavingsAccount: `${URL}/client/round-up/user/set-savings-account`,
  retrieveAccounts: `${URL}/client/round-up/user/retrieve-accounts`,
  updateRoundUpAccounts: `${URL}/client/round-up/user/update-roundup-accounts`,
  setRoundUpAccounts: `${URL}/client/round-up/user/set-roundup-accounts`,
  setPaused: `${URL}/client/round-up/user/set-pause`,
  donateNow: `${URL}/client/round-up/user/donate-now`,
  updateFundingAccount: `${URL}/client/round-up/user/update-funding-account`,
  findClub: `${URL}/clubs/club`,
  checkJob: `${URL}/client/round-up/user/check-job`,
  onboardingComplete: `${URL}/client/round-up/user/onboarding-complete`,
  registerFunding: `${URL}/client/round-up/user/register-funding`,
  findJobs: `${URL}/handlers/basiq/find-jobs`,

}


