export const redirects = {
    lifechanger: 'https://lifechanger.sipora.io/consented?userId=',
    aca: 'https://aca.sipora.io/consented?userId=',
    cmi: 'https://cmi.sipora.io/consented?userId=',
    familyofleague: 'https://familyofleague.sipora.io/consented?userId=',
    tsb: 'https://familyofleague.sipora.io/consented?userId=',
    ecoforce: 'https://ss4h.sipora.io/consented?userId=',
    ss4h: 'https://ecoforce.sipora.io/consented?userId=',
    demo: 'https://demo.sipora.io/consented?userId=',
    standbyu: 'https://standbyu.sipora.io/consented?userId=',
    mndqld: 'https://mndqld.sipora.io/consented?userId=',
}



export const parent_clubs = {
    // include ACA as a club as well in it's parent array
 aca_clubs: [
    '66e2422d6298959464dd950d',
 ],
 lifechanger_clubs: [
    '654987321',
    '106543254'
 ],



}


